import { Text } from "@chakra-ui/react";
import React from "react";

export const AboutCultureTest = () => {
  return (
    <div>
      <Text fontSize="xl">
        質問が6つありそれぞれ4つの選択肢があります。
        <br />
        企業に求める雰囲気の合計を100としてそれぞれの選択肢がどの程度重要であるか合計値が100になるように振り分けてください
      </Text>
    </div>
  );
};
